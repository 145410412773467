<template>
  <div :style="this.CSS" :class="CSSClasses" v-show="!isHidden">
    <el-switch
      v-model="localModel"
      :disabled="_isReadonly"
      :name="name"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      :active-text="activeText"
      :inactive-text="inactiveText"
      :width="localWidth"
      @change="change"
    ></el-switch>
  </div>
</template>

<script>
import Vue from 'vue'
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

export default Vue.extend({
  name: 'a-switch',

  mixins: [mixin, VisibleMixin],

  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },

  props: {
    model: {
      frozen: true
    },

    editorAlias: {
      type: String,
      description: 'alias'
    },

    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },

    activeText: {
      type: String,
      description: 'active_text',
      default: null
    },

    inactiveText: {
      type: String,
      description: 'inactive_text',
      default: null
    },

    width: {
      type: String,
      description: 'width',
      default: '40px'
    },

    // size: {
    //   type: String,
    //   description: 'Размер',
    //   default: 'mini',
    //   editor: 'Size'
    // },

    activeColor: {
      type: String,
      description: 'active_color',
      default: '#409EFF',
      editor: 'ColorPicker'
    },

    inactiveColor: {
      type: String,
      description: 'inactive_color',
      default: '#C0CCDA',
      editor: 'ColorPicker'
    }

    // valueType: {
    //   type: String,
    //   description: 'Тип значения',
    //   default: 'boolean',
    //   editor: 'Select',
    //   options: {
    //     multiple: false,
    //     options: [
    //       { name: 'Логическое', id: 'boolean' },
    //       { name: 'Строка', id: 'string' },
    //       { name: 'Число', id: 'number' }
    //     ]
    //   }
    // },
    //
    // activeValue: {
    //   type: [Boolean, String, Number],
    //   description: 'Активное значение',
    //   default: true
    // },
    //
    // inactiveValue: {
    //   type: [Boolean, String, Number],
    //   description: 'Неактивное значение',
    //   default: false
    // }
  },

  data () {
    return {
      localModel: typeof this.$attrs.value !== 'boolean'
        ? false
        : this.$attrs.value
    }
  },

  computed: {
    localWidth () {
      const width = parseInt(this.width)

      if (isNaN(width)) {
        return 40
      }

      return width
    }
  },

  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },

  mounted () {
    this.$emit('input', this.localModel)
  },

  methods: {
    change (value) {
      this.$emit('input', value)
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
